<template>
  <div>
    <div v-if="$store.state.AppActiveUser.userType !== 'superAdmin' && (organizationId || isDoctor) && clinics.length" class="relative z-30" style="min-width: 180px;">
      <div class="flex items-center w-full justify-around whitespace-normal">
        <div class="w-full flex items-center justify-center gap-0 md:block font-normal text-primary2" @click="popupActive = !popupActive" >
          <svg class="md:mx-2 ml-2 md:hidden" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.8 14.025C6.8 14.3632 6.66567 14.6875 6.42656 14.9266C6.18745 15.1657 5.86315 15.3 5.525 15.3C5.18685 15.3 4.86255 15.1657 4.62344 14.9266C4.38433 14.6875 4.25 14.3632 4.25 14.025C4.25 13.6868 4.38433 13.3625 4.62344 13.1234C4.86255 12.8843 5.18685 12.75 5.525 12.75C5.86315 12.75 6.18745 12.8843 6.42656 13.1234C6.66567 13.3625 6.8 13.6868 6.8 14.025ZM12.75 14.025C12.75 14.3632 12.6157 14.6875 12.3766 14.9266C12.1375 15.1657 11.8132 15.3 11.475 15.3C11.1368 15.3 10.8125 15.1657 10.5734 14.9266C10.3343 14.6875 10.2 14.3632 10.2 14.025C10.2 13.6868 10.3343 13.3625 10.5734 13.1234C10.8125 12.8843 11.1368 12.75 11.475 12.75C11.8132 12.75 12.1375 12.8843 12.3766 13.1234C12.6157 13.3625 12.75 13.6868 12.75 14.025Z" fill="#9CA3AF"/>
<path d="M2.55001 3.40039C2.32458 3.40039 2.10838 3.48994 1.94897 3.64935C1.78957 3.80876 1.70001 4.02496 1.70001 4.25039V12.7504C1.70001 12.9758 1.78957 13.192 1.94897 13.3514C2.10838 13.5108 2.32458 13.6004 2.55001 13.6004H3.44251C3.54006 13.12 3.80067 12.6881 4.1802 12.3779C4.55973 12.0677 5.03484 11.8983 5.52501 11.8983C6.01519 11.8983 6.49029 12.0677 6.86982 12.3779C7.24935 12.6881 7.50997 13.12 7.60751 13.6004H8.50001C8.72545 13.6004 8.94165 13.5108 9.10105 13.3514C9.26046 13.192 9.35001 12.9758 9.35001 12.7504V4.25039C9.35001 4.02496 9.26046 3.80876 9.10105 3.64935C8.94165 3.48994 8.72545 3.40039 8.50001 3.40039H2.55001ZM11.9 5.95039C11.6746 5.95039 11.4584 6.03994 11.299 6.19935C11.1396 6.35876 11.05 6.57496 11.05 6.80039V11.9429C11.3235 11.887 11.6053 11.8856 11.8793 11.9386C12.1534 11.9917 12.4143 12.0983 12.6471 12.2522C12.88 12.4061 13.0802 12.6044 13.2364 12.8357C13.3927 13.067 13.5018 13.3269 13.5575 13.6004H14.45C14.6754 13.6004 14.8916 13.5108 15.0511 13.3514C15.2105 13.192 15.3 12.9758 15.3 12.7504V8.50039C15.3 8.27498 15.2104 8.05881 15.051 7.89944L13.351 6.19944C13.1916 6.04002 12.9754 5.95044 12.75 5.95039H11.9Z" fill="#9CA3AF"/>
</svg>

          <p class="leading-tight text-black whitespace-no-wrap mr-2 font-medium md:mr-0 text-sm md:text-base">{{ title }}</p>
          <h5 v-if="activeClinic.clinicName" class="cursor-pointer flex items-center gap-2">
            <span class="md:font-semibold truncate whitespace-normal md:whitespace-no-wrap text-sm md:text-base font-medium text-black">{{ activeClinic.clinicName ? activeClinic.clinicName : "" }}</span>
            <span class="hidden md:flex justify-between items-center btn-circle cursor-pointer" @click="popupActive = !popupActive">
              <vs-icon icon="expand_more" v-if="popupActive == false" @click="popupActive = true" size='small' color="primary2"></vs-icon>
              <vs-icon icon="expand_less" v-if="popupActive == true" @click="popupActive = false" size='small' color="primary2"></vs-icon>
            </span>
          </h5>
        </div>
      </div>
      <div v-if="popupActive" class="nav-switch-org" style="width: 200px; z-index: 10000000; max-height: 70vh; overflow: auto;">
        <div v-for="item in clinics" :key="item._id" class="flex justify-between items-center nav-item-hover"
          @click="onChange(item)">
          <div class="w-full px-6">
            <div class="flex justify-between nav-item items-center cursor-pointer">
              <div class="nav-item-content">
                <div class="nav-item-content-title">
                  {{ item.clinicName | capitalize }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <vs-select v-model="clinicId" class="rounded-lg w-full">
        <vs-select-item
          :key="index"
          :value="item._id"
          :text="item.clinicName"
          v-for="(item, index) in clinics"
        />
      </vs-select> -->
    </div>
    <div v-if="$store.state.AppActiveUser.userType === 'superAdmin'" style="min-width: 180px;">
      <div class="w-full font-normal text-primary2" @click="popupActive = !popupActive" >
          <p class="text-sm leading-tight">{{ title }}</p>
          <h5 v-if="activeClinic.clinicName" class="cursor-pointer flex items-center gap-2">
            <span class="font-semibold truncate text-base text-primary2">{{ activeClinic.clinicName ? activeClinic.clinicName : "" }}</span>
            <!-- <span class="flex justify-between items-center btn-circle cursor-pointer" @click="popupActive = !popupActive">
              <vs-icon icon="expand_more" v-if="popupActive == false" @click="popupActive = true" size='small' color="primary2"></vs-icon>
              <vs-icon icon="expand_less" v-if="popupActive == true" @click="popupActive = false" size='small' color="primary2"></vs-icon>
            </span> -->
          </h5>
          <h5 v-else class="cursor-pointer flex items-center gap-2">Select Clinic</h5>
      </div>
        <admin-clinic-select
          :propClinicId="clinicId"
          :popupActive="popupActive"
          @closeModal="popupActive=!popupActive"
          @onOrganizationChange="onOrganizationChange"
          @onSubmit="onChange"
          />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { ChevronDownIcon, ChevronUpIcon, XIcon } from "vue-feather-icons";
import AdminClinicSelect from "./AdminClinicSelect.vue";
export default {
  name: "ClinicSwap",
  props: ["title", "isDoctor"],
  data: () => ({
    clinicId: null,
    organizationId: null,
    clinics: [],
    activeClinic: {},
    popupActive: false,
    isClinicEnabled: false,
  }),
  computed: {
    selectedClinic: {
      get() {
        return this.$store.state.clinicId;
      },
      set(value) {
        this.updateClinic(value);
      },
    },
    isLoggedInSuperAdmin() {
      return this.$store.state.AppActiveUser.userType === "superAdmin"
    }
  },
  watch: {
    selectedClinic: function (val, oldVal) {
      if (oldVal !== null && oldVal !== val) {
        this.clinicId = val;
      }
    },
    organizationId: function (val, oldVal) {
      if (!val) {
        localStorage.removeItem('selectedOrg')
        localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`);
        return;
      }
      if (!oldVal) {
        this.getClinics();
        return;
      }
      if (
        this.isLoggedInSuperAdmin &&
        oldVal && oldVal !== val && val
      ) {
        this.getClinics();
        return;
      }
    },
    clinicId: function (val, oldVal) {
      if(val){
        this.updateClinic(val, oldVal);
      }
      else{
        sessionStorage.removeItem("doctorClinicSelectedClinic");
        this.activeClinic = {}
      }
    },
  },
  methods: {
    ...mapActions("organization", ["fetchOrganizationClinicsList"]),
    ...mapMutations(["UPDATE_CLINIC_ID", "UPDATE_CLINIC"]),
    updateClinic(val, oldVal) {
      let operations = {};
      const clinic = this.clinics.filter(
        ({ _id }) => _id.toString() === val.toString()
      );

      // First selected clinic(clinics at this time is not populated yet)
      if (!clinic.length && this.isLoggedInSuperAdmin) {
        const selectedIds = JSON.parse(localStorage.getItem('selectedIds'))
        if (selectedIds) {
          const hours = selectedIds.clinicId.clinicOpeningHours
          if (Object.keys(hours).length) {
            Object.keys(hours).map((k) => {
              if (k !== "_id") {
                operations[k.slice(0, -1)] = hours[k];
              }
            });
          }
          sessionStorage.setItem("doctorClinicSelectedClinic", val);
          this.UPDATE_CLINIC_ID({
            clinicId: val,
            clinic: [selectedIds.clinicId],
            operations
          });
        }
        return;
      }

      // For disabled clinic
      if (!clinic.length && !this.isLoggedInSuperAdmin) {
        sessionStorage.setItem("doctorClinicSelectedClinic", val);
        this.UPDATE_CLINIC_ID({
          clinicId: null,
          clinic,
          operations: null
        });
        return;
      }

      this.activeClinic = clinic[0];
      const hours = clinic[0].clinicOpeningHours
      if (Object.keys(hours).length) {
        Object.keys(hours).map((k) => {
          if (k !== "_id") {
            operations[k.slice(0, -1)] = hours[k];
          }
        });
      }
      if (oldVal !== val) {
        sessionStorage.setItem("doctorClinicSelectedClinic", val);
        this.UPDATE_CLINIC_ID({
          clinicId: val,
          clinic,
          operations,
        });
        return
      }

      if (val !== null) {
        this.UPDATE_CLINIC(
          clinic
        )
      }
    },
    onOrganizationChange(val) {
      if (!val) {
        localStorage.removeItem('selectedOrg')
        localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`);
        localStorage.removeItem('selectedIds')
        this.organizationId = null;
        return;
      }
      this.organizationId = val._id;
      localStorage.setItem('selectedOrg', JSON.stringify(val));
      localStorage.setItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`, val._id);
    },
    onChange(val) {
      if (this.isLoggedInSuperAdmin) {
        localStorage.setItem('selectedIds', JSON.stringify(val))
        this.clinicId = val.clinicId._id;

        // notify super-admin if selected clinic is disabled
        if (val.clinicId.status !== "Active") {
          this.$vs.notify({
            title: "Notification",
            text: "Clinic selected is disabled",
            color: "warning",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      } else {
        this.clinicId = val._id;
      }
      this.popupActive = false;
    },
    getClinics() {
      this.fetchOrganizationClinicsList({
        id: this.organizationId,
        limit: 200,
        sort: "clinicName",
        dir: "asc",
      })
        .then((res) => {
          this.clinics = res.data.data.docs
            .filter(({ showStore }) => showStore)
            .map(
              ({
                _id,
                clinicName,
                clinicOpeningHours,
                address,
                paymentTypes,
                bestContactName,
                bestContactNumber,
                bestContactEmail,
                storeInvoiceEmail,
              }) => ({
                _id,
                clinicName,
                clinicOpeningHours,
                address,
                paymentTypes,
                bestContactName,
                bestContactNumber,
                bestContactEmail,
                storeInvoiceEmail,
              })
            );
          if (this.clinics.length) {
            if (!sessionStorage.getItem("doctorClinicSelectedClinic")) {
              this.clinicId = this.clinics[0]._id;
              this.activeClinic = this.clinics[0];
              const hours = this.clinics[0].clinicOpeningHours;
              let operations = {};
              if (Object.keys(hours).length) {
                Object.keys(hours).map((k) => {
                  if (k !== "_id") {
                    operations[k.slice(0, -1)] = hours[k];
                  }
                });
              }
              this.UPDATE_CLINIC_ID({
                clinicId: this.clinics[0]._id,
                clinic: [this.clinics[0]],
                operations
              });
            } else {
                this.clinicId = sessionStorage.getItem(
                  "doctorClinicSelectedClinic"
                );
            }

               
            this.isClinicEnabled = this.clinics.filter(
                ({ _id }) => _id.toString() === this.clinicId.toString()
            )

            if(this.isClinicEnabled > 0) {
              this.activeClinic = this.clinics.filter(
                ({ _id }) => _id.toString() === this.clinicId.toString()
              )[0];
              
            } else {
              this.activeClinic = this.clinics[0];
            }
 
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeMount: async function () {
    this.organizationId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
  },
  created() {
    if (this.isDoctor) {
      this.clinics = this.$store.state.AppActiveUser.associatedClinicIds
        .filter(({ clinicId }) => clinicId.showStore)
        .map(
          ({
            clinicId: { _id, clinicName, clinicOpeningHours, paymentTypes },
          }) => ({
            _id,
            clinicName,
            clinicOpeningHours,
            paymentTypes,
          })
        );

      if (!sessionStorage.getItem("doctorClinicSelectedClinic")) {
        this.clinicId = this.clinics[0]._id;
      } else {
        this.clinicId = sessionStorage.getItem("doctorClinicSelectedClinic");
      }
    }
  },
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    AdminClinicSelect
  },
};
</script>
